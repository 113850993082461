import { useQuery } from '@tanstack/react-query';

import { ISbStoryData } from '@storyblok/react';
import GetPageItemById from './GetPageItemById.graphql';
import { GQLPageItemResponse } from './types';
import { storyblokGraphQLClient } from '@/utils/storyblok';
import getErrorMessage from '@/utils/errors';

// id is Storyblok item full_slug
// locale should be prefixed to retrieve items with translated content
interface PageItem {
    id: string;
    locale: string;
    preview: boolean;
}

export const generatePageQueryKey = ({
    id,
    locale,
}: Pick<PageItem, 'id' | 'locale'>) => ['page-content', id, locale];

export const fetchPageItem = async ({
    preview,
    id,
}: Pick<PageItem, 'id' | 'preview'>): Promise<ISbStoryData> => {
    try {
        const { PageItem }: GQLPageItemResponse = await storyblokGraphQLClient(
            preview
        ).request(GetPageItemById, {
            id,
        });

        return PageItem;
    } catch (error) {
        //TODO: handle logging errors better, reusable fn wrapper
        const errorMessage = getErrorMessage(error);
        console.error({ message: errorMessage });
        throw new Error(errorMessage);
    }
};

// Get page content by page id (full_slug)
// Generates query key using id and locale with page-content prefixed
export const usePageItem = ({ id, locale, preview }: PageItem) =>
    useQuery(generatePageQueryKey({ id, locale }), () =>
        fetchPageItem({ preview, id })
    );
